:root {
  --headline-font: "Roboto", sans-serif;
  --body-font: "Source Sans Pro", sans-serif;
  --logo-font: "Zeyada", cursive;
  --main-color: #26759a;
  --light-accent-color: #3dbdd7;
  --dark-shade-color: #213248;
  --light-shade-color: #e2e4eb;
}

html {
  scroll-padding-top: 5rem;
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: var(--body-font);
}

* {
  box-sizing: border-box;
  /* border: 1px dashed red; */
}

h1,
h2,
h3,
h4 {
  font-family: var(--headline-font);
  font-weight: 700;
}

a {
  text-decoration: none;
  color: var(--light-accent-color);
}

a:hover {
  color: var(--main-color);
}

/**** HEADER STYLES ****/
.logo > a {
  font-family: var(--logo-font);
  font-size: 1.6rem;
  color: #ffffff;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  background-color: var(--light-accent-color);
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100vw;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navlink > a {
  color: #ffffff;
}

.navlink > a:hover {
  color: var(--main-color);
}

nav > div {
  margin-left: 1rem;
}

/**** MAIN SECTION STYLES ****/

.contact-container,
.section-container,
.hero {
  padding: 2rem 3rem;
  width: 100vw;
}

.hero {
  min-height: 90vh;
}

.section-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  background-color: #ffffff;
}

.section-header > h2 {
  display: inline-block;
  margin: 0;
  padding: 0 2rem;
  z-index: 5;
  letter-spacing: 1px;
  font-weight: 500;
  color: var(--main-color);
  font-family: var(--logo-font);
  font-size: 2rem;
}

.header-line {
  display: inline-block;
  height: 2px;
  width: 90%;
  right: 1%;
  z-index: 0;
  position: absolute;
  background-color: var(--light-accent-color);
}

/**** CONTACT STYLES ****/

.contact-container,
#contact,
#contact > h2 {
  background-color: var(--light-shade-color);
}

.contact-description {
  width: 70%;
  margin: 2rem auto;
  text-align: center;
  font-size: 1.2rem;
}

form {
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

form > button {
  width: 150px;
  align-self: center;
}

/**** FOOTER STYLES ****/

footer {
  background-color: var(--light-accent-color);
  padding: 1rem;
  width: 100vw;
}

footer > p {
  margin: 0;
  text-align: center;
  font-family: var(--logo-font);
  font-size: 1.3rem;
  letter-spacing: 0.5px;
}

/* Media query for tablets */
@media only screen and (max-width: 800px) {
  .hero {
    min-height: 50vh;
  }

  .contact-description {
    width: 90%;
  }

  form {
    width: 90%;
  }
}

/* Media query for phones */
@media only screen and (max-width: 600px) {
  html {
    font-size: 12px;
  }

  .contact-description {
    font-size: 1rem;
  }

  .hero {
    min-height: 95vh;
  }
}

@media only screen and (max-width: 450px) {
  .logo > a {
    font-size: 1.3rem;
  }

  header {
    padding: 1.1rem 1.5rem;
  }

  nav > div {
    font-size: 0.9rem;
    margin-left: 0.8rem;
  }

  .hero {
    min-height: 80vh;
  }

  .contact-container,
  .section-container,
  .hero {
    padding: 2rem 1rem 3rem;
    width: 100vw;
  }

  .section-header {
    flex-direction: column;
    margin: 1rem 0;
  }

  .header-line {
    display: block;
    height: 2px;
    position: static;
    background-color: var(--light-accent-color);
  }
}
